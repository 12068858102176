<template>
    <div>
        <Card>
            <template #title>
                <h2>{{ $t("tags") }}</h2>
            </template>

            <template #content>
                <LoadingWrapper :loading="loading">
                    <table class="cc-table">
                        <thead>
                            <tr>
                                <th class="text-left">{{ $t("tag") }}</th>
                                <th class="text-left">
                                    {{ $t("times_used") }}
                                </th>
                                <th></th>
                                <th>{{ $t("label_actions") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="tag in tags" :key="tag.title">
                                <td>
                                    <Badge
                                        class="badge tag cursor-pointer"
                                        @click="setFilterAndNavigate(tag)"
                                    >
                                        {{ tag.title }}
                                    </Badge>
                                </td>
                                <td>{{ tag.count }}</td>
                                <td></td>
                                <td width="10%">
                                    <div class="flex gap-2">
                                        <Button
                                            size="small"
                                            outlined
                                            rounded
                                            icon="fas fa-pencil"
                                            @click="handleEdit(tag)"
                                            v-tooltip.bottom="$t('edit')"
                                        ></Button>
                                        <Button
                                            size="small"
                                            outlined
                                            rounded
                                            severity="danger"
                                            icon="fas fa-trash"
                                            @click="handleDelete(tag)"
                                            v-tooltip.bottom="$t('delete')"
                                        ></Button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="100%">
                                    <p
                                        class="text-small text-gray-500 cursor-pointer light"
                                        @click="handleAddTag"
                                        v-if="$can('create', 'tag')"
                                    >
                                        <i
                                            class="fa-regular fa-circle-plus"
                                        ></i>
                                        {{ $t("add_tag") }}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </LoadingWrapper>
            </template>
        </Card>

        <TagForm
            :edited-tag="selectedTag"
            :showForm="showForm"
            @update:show-form="showForm = $event"
            @refresh="init"
        />

        <ConfirmDialog group="delete-tag">
            <template #message>
                <div class="text-left light">
                    <p>{{ $t("tags_handleDelete_html_question") }}</p>
                    <Badge class="badge tag">{{ selectedTag.title }}</Badge>
                    <p
                        v-html="
                            $t('tags_handleDelete_html_applied', {
                                tag: selectedTag.count,
                            })
                        "
                    ></p>
                </div>
            </template>
        </ConfirmDialog>
    </div>
</template>

<script>
import tagsApi from "@/api/tags";
import Card from "primevue/card";
import Badge from "primevue/badge";
import Button from "primevue/button";
import TagForm from "./tags/TagForm.vue";
import ConfirmDialog from "primevue/confirmdialog";
import LoadingWrapper from "@/components/elements/LoadingWrapper.vue";

import * as types from "@/store/types";

export default {
    name: "CCTags",
    components: {
        Card,
        Badge,
        Button,
        TagForm,
        ConfirmDialog,
        LoadingWrapper,
    },
    data() {
        return {
            tags: [],
            selectedTag: null,
            showForm: false,
            loading: true,
        };
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.showForm = false;
            this.selectedTag = null;
            this.getTags();
        },
        async getTags() {
            this.loading = true;

            try {
                const response = await tagsApi.listAll();
                this.tags = response.data;
                this.$store.dispatch(types.SET_DATA, {
                    name: "tags",
                    data: this.tags,
                });
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        async deleteTag() {
            try {
                await tagsApi.deleteAdmin(this.selectedTag.id);
                this.init();
            } catch (error) {
                console.log(error);
            }
        },
        handleDelete(tag) {
            this.selectedTag = tag;

            this.$confirm.require({
                group: "delete-tag",
                header: this.$t("tags_handleDelete_title"),
                rejectProps: {
                    label: this.$t("cancel"),
                    class: "secondary",
                    outlined: true,
                },
                acceptProps: {
                    label: this.$t("delete"),
                    severity: "danger",
                },
                accept: () => {
                    this.deleteTag();
                },
            });
        },
        handleEdit(tag) {
            this.selectedTag = tag;
            this.showForm = true;
        },
        handleAddTag() {
            this.selectedTag = null;
            this.showForm = true;
        },
        async setFilterAndNavigate(tag) {
            await this.$store.dispatch(types.SET_FILTER, { tag: tag.id });
            this.$router.push({ name: "DataInput", query: { expandAll: "1" } });
        },
    },
};
</script>
