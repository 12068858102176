<template>
    <div class="relative">
        <i
            v-if="prependIcon"
            :class="[prependIconClass, 'cc-select__icon']"
        ></i>
        <Select
            v-model="inputValue"
            :options="options"
            :disabled="disabled"
            :placeholder="placeholder"
            :required="required"
            :optionLabel="textField"
            :optionValue="valueField"
            :showClear="clearable"
            fluid
            :filter="filter"
            class="cc-select text-sm"
            :class="[
                appendIcon ? 'cc-select--icon' : '',
                prependIcon ? 'cc-select--prepend' : '',
                clearable ? 'cc-select--clearable' : '',
                error ? 'cc-select--error' : '',
                customClasses,
            ]"
        >
            <template #empty v-if="emptyOption">
                <p :value="null" disabled>{{ emptyText }}</p>
            </template>
        </Select>
    </div>
</template>

<script>
import Select from "primevue/select";

export default {
    components: { Select },
    props: {
        modelValue: {
            type: [String, Number, Object],
            required: false,
        },
        options: {
            type: Array,
            required: true,
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false,
        },
        prependIcon: {
            type: String,
            required: false,
            default: null,
        },
        appendIcon: {
            type: Boolean,
            required: false,
            default: null,
        },
        customClasses: {
            type: String,
            required: false,
            default: "",
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        error: {
            type: Boolean,
            required: false,
            default: false,
        },
        placeholder: {
            type: String,
            required: false,
            default: "",
        },
        emptyOption: {
            type: Boolean,
            required: false,
            default: false,
        },
        emptyText: {
            type: String,
            required: false,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        textField: {
            type: String,
            required: false,
            default: "text",
        },
        valueField: {
            type: String,
            required: false,
            default: "value",
        },
        filter: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        inputValue: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            },
        },
        prependIconClass() {
            return this.prependIcon ? this.prependIcon : "fas fa-tag";
        },
    },
};
</script>
