<template>
    <div class="cc-form-group">
        <label>{{ $t("user_form_label_allowed_sites") }}</label>
        <div class="cc-form-group cc-form-group--inline">
            <ToggleSwitch v-model="all" @change="handleChange" />
            <label>{{ $t("form_checkbox") }}</label>
        </div>
        <MultiSelect
            v-if="!all"
            v-model="selected"
            :options="sites"
            optionLabel="name"
            optionValue="id"
            @update:model-value="handleChange"
            required
        />
        <div class="cc-form__hint">
            {{ $t("user_form_label_allowed_sites_description") }}
        </div>
    </div>
</template>

<script>
import ToggleSwitch from "primevue/toggleswitch";
import MultiSelect from "primevue/multiselect";

export default {
    components: { ToggleSwitch, MultiSelect },
    props: {
        userSelected: {
            type: Array,
            required: false,
            default: () => [],
        },
        userAll: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            sites: this.$store.getters.getSites(),
            selected: [],
            all: false,
        };
    },
    mounted() {
        this.selected = this.userSelected;
        this.all = this.userAll;
    },
    methods: {
        handleChange() {
            this.$emit("update", { selected: this.selected, all: this.all });
        },
    },
};
</script>
