<template>
    <div class="login__container">
        <div class="grid">
            <div class="col login__background"></div>
            <div class="col login__form">
                <img
                    src="letter_logo.svg"
                    height="42"
                    class="login__logo text-center"
                />
                <span class="login__fields">
                    <h1>{{ $t("login_header") }}</h1>
                    <div>
                        <p class="login__fields-text">
                            {{ $t("login_if_have_account_text") }}
                        </p>
                        <Button fluid @click="login">
                            {{ $t("login_button") }}
                        </Button>
                    </div>
                    <Divider />
                    <div>
                        <p class="login__fields-text">
                            {{ $t("login_trial_text") }}
                        </p>
                        <Button outlined fluid @click="freeTrial">
                            {{ $t("login_button_free_trial") }}
                        </Button>
                    </div>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import * as types from "@/store/types";
import Button from "primevue/button";
import Divider from "primevue/divider";

export default {
    name: "CCLogin",
    components: {
        Button,
        Divider,
    },
    data() {
        return {};
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.$store.dispatch(types.SHOW_LOADER, false);
            this.$store.dispatch(types.SET_DATA, {
                name: "preloaded",
                data: true,
            });
            if (this.$auth0.isAuthenticated.value) {
                this.$router.push("/dashboard");
            }
            if (this.$route.query.action === "login")
                this.$auth0.loginWithRedirect();
        },
        freeTrial() {
            this.$router.push("starttrial");
        },
        login() {
            this.$auth0.loginWithRedirect();
        },
    },
};
</script>
