<template>
    <div>
        <cc-header
            class="border-bottom"
            :title="$t('cm_header')"
            icon="far fa-flag"
        ></cc-header>

        <LoadingWrapper :loading="loading" class="content-container">
            <metric-list />
        </LoadingWrapper>
    </div>
</template>

<script>
import MetricList from "@/components/custom_metrics/MetricList.vue";
import LoadingWrapper from "@/components/elements/LoadingWrapper.vue";
import CcHeader from "@/components/header/CcHeader.vue";

export default {
    name: "CompanyMetrics",
    components: {
        CcHeader,
        LoadingWrapper,
        MetricList,
    },
};
</script>
