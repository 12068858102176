<template>
    <Card>
        <template #title>
            <div class="ml-2">
                <p class="text-mini text-500 m-0">{{ $t("in_ton") }}</p>
                <h2 class="mt-2">{{ $t("dashboard_target_chart_header") }}</h2>
            </div>
        </template>

        <template #content>
            <transition mode="out-in" name="slow-fade">
                <template v-if="!loading">
                    <TargetGraph
                        :data="evolutionData"
                        chartKey="evolutionData"
                        labelKey="period_name"
                        idKey="year"
                        :stackKeys="['scope1', 'scope2', 'scope3']"
                        @bar-clicked="handleBarClicked($event)"
                        :targetLine="targetLineData"
                        :padding="
                            Object.keys(reductionData).length > 0 ? 0.85 : 0.95
                        "
                        :width="1400"
                    ></TargetGraph>
                </template>

                <Skeleton width="100%" height="200px" v-else />
            </transition>
        </template>
    </Card>
</template>

<script>
import insightApi from "@/api/insights";
import TargetGraph from "@/components/charts/TargetGraph.vue";
import Card from "primevue/card";
import Skeleton from "primevue/skeleton";

export default {
    components: {
        TargetGraph,
        Card,
        Skeleton,
    },
    data() {
        return {
            loading: true,
            totalTargetReductions: [],
            evolutionData: [],
            preparedData: [],
            yearlyEmissions: [],
            targetLineData: { data: [] },
            reductionData: {},
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.getReductionTargets();
            await this.getYearlyEmissions();
            this.generateEvolutionData();
        },
        async getYearlyEmissions() {
            try {
                const response = await insightApi.yearlyEmissionsPerScope();
                this.yearlyEmissions = response.data;
            } catch (error) {
                console.log(error);
            }
        },
        async getReductionTargets() {
            if (!this.$can("read", "reduction_target")) return;
            try {
                const response = await insightApi.reductionTargets();
                if (response.data && response.data !== "")
                    this.reductionData = response.data;
            } catch (error) {
                console.log(error);
            }
        },
        handleBarClicked(data) {
            const period = this.$store.getters.getClosestPeriod(data.data.year);
            if (period)
                this.$emit("chart-clicked", { ...data, period_id: period.id });
            else this.$emit("chart-clicked", { ...data });
        },
        generateEvolutionData() {
            this.loading = true;

            // Clear previous data
            this.targetLineData = { data: [] };
            this.evolutionData = [];

            // See which years we're provided with
            const years = new Set([...this.yearlyEmissions.map((x) => x.year)]);
            // Compile data for each year
            years.forEach((year) => {
                const emissions = {};
                const selectedYear = this.yearlyEmissions.filter(
                    (x) => x.year === year
                );
                selectedYear.forEach((x) => {
                    emissions[`scope${x.scope}`] = x.emissions;
                });

                this.evolutionData.push({
                    year,
                    period_id: selectedYear[0].period_id,
                    period_name: year.toString(),
                    emissions,
                    unit: "tCO₂e",
                });
            });

            // No targets set? Don't do the next bit.
            if (Object.keys(this.reductionData).length === 0)
                return (this.loading = false);

            // Figure out from which year we should start showing the projection
            const latestInputYear = Math.max(
                ...this.yearlyEmissions.map((x) => x.year)
            );
            const earliestBaseYear = Math.min(
                ...this.evolutionData.map((x) => x.year)
            );

            // Add prediction until 2030
            for (let i = earliestBaseYear; i < 2031; i++) {
                if (!this.reductionData[i]) continue;

                const evolutionDataPoint = {
                    year: i,
                    period_name: i.toString(),
                    unit: "tCO2e",
                    emissions: this.reductionData[i].total,
                };

                // Add prediction to the barchart when we've passed the currently available data, add it to the targetlinedata anyway
                if (i > latestInputYear)
                    this.evolutionData.push(evolutionDataPoint);
                this.targetLineData.data.push(evolutionDataPoint);
            }

            this.loading = false;
        },
    },
};
</script>
