<template>
    <div>
        <cc-header
            :title="$t('dashboard_title')"
            icon="far fa-home"
        ></cc-header>

        <ControlBar :hide="['filters', 'sort']" :hideBadges="true" />

        <LoadingWrapper :loading="loading" class="mt-2">
            <div class="flex flex-column gap-4">
                <!-- 3 instant overview cards at the top -->
                <DashboardStatCards
                    :totalEmissions="totalEmissions"
                    :totalEmissionsPerFTE="totalEmissionsPerFTE"
                    :totalEmissionsPerMillionEuro="totalEmissionsPerMillionEuro"
                />

                <!-- General chart with targets -->
                <div v-if="!limitedSites">
                    <DashboardTargetChart
                        @chart-clicked="handleChartClicked"
                        v-if="!loading"
                    />
                </div>

                <!-- Additional dashboard charts -->
                <div class="grid mt-4">
                    <div class="col-12 lg:col-4">
                        <DashboardValidationCard />

                        <DashboardScopeChart
                            :data="data"
                            @chart-clicked="handleChartClicked"
                            v-if="!loading"
                            class="mt-4"
                        />

                        <DashboardValueChainChart
                            :data="data"
                            @chart-clicked="handleChartClicked"
                            v-if="!loading"
                            class="mt-4"
                        />
                    </div>
                    <div class="col-12 lg:col-8">
                        <DashboardGHGChart
                            :data="data"
                            @chart-clicked="handleChartClicked"
                            v-if="!loading"
                        />
                        <DashboardActivityWidget
                            v-if="$can('read', 'dashboard')"
                            class="mt-4"
                        />
                    </div>
                </div>
            </div>
        </LoadingWrapper>
    </div>
</template>

<script>
import reportApi from "@/api/report";

import CcHeader from "@/components/header/CcHeader.vue";
import ControlBar from "../components/controls/ControlBar.vue";

import DashboardStatCards from "@/components/dashboard/DashboardStatCards";
import DashboardTargetChart from "@/components/dashboard/DashboardTargetChart";
import DashboardScopeChart from "@/components/dashboard/DashboardScopeChart";
import DashboardValueChainChart from "../components/dashboard/DashboardValueChainChart.vue";
import DashboardGHGChart from "../components/dashboard/DashboardGHGChart.vue";
import DashboardValidationCard from "../components/dashboard/DashboardValidationCard.vue";
import DashboardActivityWidget from "../components/dashboard/DashboardActivityWidget.vue";

import { mapState } from "vuex";
import LoadingWrapper from "@/components/elements/LoadingWrapper.vue";

export default {
    name: "CCDashboard",
    components: {
        CcHeader,
        DashboardStatCards,
        DashboardTargetChart,
        DashboardScopeChart,
        DashboardValueChainChart,
        DashboardGHGChart,
        ControlBar,
        DashboardValidationCard,
        DashboardActivityWidget,
        LoadingWrapper,
    },
    data() {
        return {
            totalEmissions: 0,
            totalEmissionsPerFTE: 0,
            totalEmissionsPerMillionEuro: 0,
            uncertainty: 0,
            loading: true,
            data: {},
        };
    },
    computed: {
        ...mapState({
            periodId: (state) => state.filters.period,
        }),
        limitedSites() {
            return this.$store.getters.getPermissions("sites").length > 0;
        },
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            await this.getData();
        },
        async getData() {
            if (this.periodId == undefined) return;
            if (!this.$can("read", "dashboard"))
                return this.$router.push("/data-input");

            this.loading = true;

            try {
                const res = await reportApi.get(this.periodId);
                this.totalEmissions = res.data.total
                    ? this.$checkDecimals(res.data.total)
                    : 0;
                this.totalEmissionsPerFTE = res.data.totalPerFTE
                    ? this.$checkDecimals(res.data.totalPerFTE)
                    : 0;
                this.totalEmissionsPerMillionEuro = res.data
                    .totalEmissionsPerMillionEuro
                    ? this.$checkDecimals(res.data.totalEmissionsPerMillionEuro)
                    : 0;
                this.data = res.data;
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        handleChartClicked(e) {
            switch (e.click) {
                case "category":
                    this.$router.push({
                        path: "/insightsv1/category",
                        query: { filters: { category: +e.id } },
                    });
                    break;
                case "scope":
                    this.$router.push({
                        path: "/insightsv1/category",
                        query: { filters: { scopes: [+e.value] } },
                    });
                    break;
                case "evolution":
                    this.$router.push({
                        path: "/insightsv1/category",
                        query: {
                            filters: {
                                scopes: [+e.scope],
                                period: e.period_id,
                            },
                        },
                    });
                    break;
                default:
                    break;
            }
        },
    },
    watch: {
        periodId: {
            handler() {
                this.init();
            },
        },
    },
};
</script>
