<template>
    <Dialog v-model:visible="localModel" modal :style="{ width: '25rem' }">
        <template #header>
            <h3>{{ $t("select_company") }}</h3>
        </template>
        <cc-select
            id="switch-company"
            v-model="companySelected"
            :options="sortedCompanies"
            filter
        ></cc-select>
        <template #footer>
            <div class="p-d-flex p-jc-end">
                <Button :label="$t('save')" @click="save" />
            </div>
        </template>
    </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import { mapState } from "vuex";

import * as types from "@/store/types";

export default {
    components: {
        Dialog,
        Button,
    },
    props: {
        modelValue: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            companySelected: "",
            sortedCompanies: [],
        };
    },
    computed: {
        ...mapState({
            company: (state) => state.company,
            companies: (state) => state.companies,
        }),
        localModel: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            },
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            if (!this.company) return;

            this.companySelected =
                sessionStorage.getItem("selectedCompany") || this.company.id;

            const sortedCompanies = this.companies.sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });

            this.sortedCompanies = sortedCompanies.map((c) => {
                let companyText = c.name;
                if (c.status === "trial") companyText = `${c.name} - trial`;
                return { value: c.id, text: companyText };
            });
            if (this.sortedCompanies.length > 1) this.$emit("show");
        },
        save() {
            // Reset all filters first, prevent errors after changing account
            this.$store.dispatch(types.RESET_FILTERS);

            // Set all preloading to false
            this.$store.dispatch(types.SET_DATA, {
                name: "preloaded",
                data: false,
            });
            this.$store.dispatch(types.SHOW_LOADER, true);
            sessionStorage.setItem("selectedCompany", this.companySelected);
            this.localModel = false;

            this.$router.push({
                path: "/",
                query: { t: new Date().getTime() },
            }); // refresh page
        },
    },
    watch: {
        company: {
            handler(val) {
                if (val) this.init();
            },
        },
    },
};
</script>
