<template>
    <div>
        <Card v-if="$can('list', 'invite')">
            <template #title>
                <div class="flex justify-content-between align-items-center">
                    <h2>{{ $t("team_invites_header") }}</h2>
                    <Button
                        size="small"
                        @click="showInviteForm = true"
                        v-if="$can('create', 'invite')"
                        :label="$t('team_invites_button_invite')"
                    ></Button>
                </div>
            </template>

            <template #content>
                <InvitesActiveSelector
                    :showActive="showActive"
                    @update:showActive="showActive = $event"
                />
                <LoadingWrapper :loading="loading">
                    <div class="mt-6">
                        <table class="cc-table">
                            <thead>
                                <tr>
                                    <td>{{ $t("label_name") }}</td>
                                    <td>{{ $t("email") }}</td>
                                    <td>{{ $t("label_expires") }}</td>
                                    <td>{{ $t("role") }}</td>
                                    <td>{{ $t("label_site_access") }}</td>
                                    <td>
                                        {{ $t("label_business_unit_access") }}
                                    </td>
                                    <td>{{ $t("label_tag_access") }}</td>
                                    <td>{{ $t("label_actions") }}</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="invite in invites"
                                    :key="invite.invite_id"
                                >
                                    <td>
                                        {{
                                            `${invite.first_name} ${invite.last_name}`
                                        }}
                                    </td>
                                    <td class="text-xs">
                                        {{ invite.email }}
                                    </td>
                                    <td>
                                        <ExpiresAtField
                                            :invite="invite"
                                            :isExpired="
                                                expiredDate(invite.expires_at)
                                            "
                                        />
                                    </td>
                                    <td>
                                        <span
                                            v-if="
                                                $can('update', 'company_user')
                                            "
                                            class="capitalize"
                                        >
                                            {{ invite.role }}
                                        </span>
                                    </td>
                                    <td>
                                        <InvitesSiteAccessField
                                            :invite="invite"
                                        />
                                    </td>
                                    <td>
                                        <InvitesBuAccessField
                                            :invite="invite"
                                        />
                                    </td>
                                    <td>
                                        <InvitesTagAccessField
                                            :invite="invite"
                                        />
                                    </td>
                                    <td>
                                        <InvitesActions
                                            :isExpired="
                                                expiredDate(invite.expires_at)
                                            "
                                            @show-invite-form="
                                                handleEditInvite(invite)
                                            "
                                            @resend-email="
                                                handleResendEmail(invite)
                                            "
                                            @revoke-invite="
                                                handleRevokeInvite(invite)
                                            "
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </LoadingWrapper>
            </template>
        </Card>

        <invite-form
            :showModal="showInviteForm"
            @update:showModal="handleCloseInvite"
            :editInvite="editInvite"
            @saved="handleCloseInvite"
        ></invite-form>
    </div>
</template>

<script>
import inviteApi from "@/api/invite";
import swal from "sweetalert2";
import InviteForm from "./InviteForm.vue";
import Card from "primevue/card";
import Button from "primevue/button";
import InvitesActiveSelector from "./invites/InvitesActiveSelector.vue";
import ExpiresAtField from "./table-fields/ExpiresAtField.vue";
import InvitesSiteAccessField from "./table-fields/InvitesSiteAccessField.vue";
import InvitesBuAccessField from "./table-fields/InvitesBuAccessField.vue";
import InvitesTagAccessField from "./table-fields/InvitesTagAccessField.vue";
import InvitesActions from "./invites/InvitesActions.vue";
import LoadingWrapper from "@/components/elements/LoadingWrapper.vue";

export default {
    name: "CCInvites",
    components: {
        InviteForm,
        Card,
        Button,
        InvitesActiveSelector,
        ExpiresAtField,
        InvitesSiteAccessField,
        InvitesBuAccessField,
        InvitesTagAccessField,
        InvitesActions,
        LoadingWrapper,
    },
    data() {
        return {
            invites: [],
            showInviteForm: false,
            editInvite: {},
            showActive: true,
            loading: true,
        };
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.getOpenInvites();
        },
        async getOpenInvites() {
            this.loading = true;
            try {
                const response = await inviteApi.getOpenInvites(
                    !this.showActive
                );
                this.invites = response.data;
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        handleEditInvite(item) {
            this.editInvite = item;
            this.showInviteForm = true;
        },
        handleCloseInvite() {
            this.editInvite = {};
            this.showInviteForm = false;
            this.getOpenInvites();
        },
        async handleResendEmail(item) {
            const result = await swal.fire({
                title: this.$t("team_invites_handleResendEmail_title"),
                text: this.$t("team_invites_handleResendEmail_text"),
                icon: "question",
                showCancelButton: true,
                cancelButtonText: this.$t("cancel"),
            });

            if (result.isConfirmed) {
                try {
                    await inviteApi.createInvite(item);
                    this.init();
                } catch (error) {
                    console.log(error);
                }
            }
        },
        async handleRevokeInvite(item) {
            const result = await swal.fire({
                title: this.$t("team_invites_handleRevokeInvite_title"),
                text: this.$t("team_invites_handleRevokeInvite_text"),
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: this.$t("cancel"),
            });

            if (result.isConfirmed) {
                try {
                    await inviteApi.revokeInvite(item.invite_id);
                    this.init();
                } catch (error) {
                    console.log(error);
                }
            }
        },
        expiredDate(date) {
            const expires = new Date(date).getTime();
            return expires < Date.now();
        },
    },
    watch: {
        showActive: {
            handler() {
                this.init();
            },
        },
    },
};
</script>
