<template>
    <div class="flex gap-4">
        <div class="flex flex-column gap-4 flex-grow-1">
            <Card>
                <template #title>
                    <div class="ml-2">
                        <p class="text-mini text-gray-500 m-0">
                            {{ $t("in_ton") }}
                        </p>
                        <h2>{{ $t("across_value_chain") }}</h2>
                    </div>
                </template>

                <template #content>
                    <transition mode="out-in" name="slow-fade">
                        <ValueChainGraph
                            :data="catData"
                            chartKey="valueChain"
                            idKey="cat_id"
                            labelKey="cat_name"
                            :highlighted="filters.category"
                            @bar-clicked="$emit('category-clicked', $event)"
                            v-if="!loading"
                        >
                        </ValueChainGraph>
                        <Skeleton width="100%" height="200px" v-else />
                    </transition>
                </template>
            </Card>

            <Card>
                <template #title>
                    <div class="ml-2">
                        <p class="text-mini text-gray-500 m-0">
                            {{ $t("in_ton") }}
                        </p>
                        <h2>{{ $t("emission_evolution") }}</h2>
                    </div>
                </template>

                <template #content>
                    <transition mode="out-in" name="slow-fade">
                        <D3Bar
                            :data="catEvolution"
                            chartKey="catEvolution"
                            labelKey="period_name"
                            idKey="period_id"
                            :highlighted="filters.period"
                            @bar-clicked="handleChartClicked"
                            v-if="!loading"
                        ></D3Bar>
                        <Skeleton width="100%" height="200px" v-else />
                    </transition>
                </template>
            </Card>

            <Card>
                <template #title>
                    <div class="ml-2">
                        <p class="text-mini text-gray-500 m-0">
                            {{ $t("in_ton") }}
                        </p>
                        <h2>{{ $t("emissions_by_source") }}</h2>
                    </div>
                </template>

                <template #content>
                    <transition mode="out-in" name="slow-fade">
                        <D3HorizontalBar
                            :data="sourceData"
                            chartKey="sourceData"
                            labelKey="name"
                            idKey="emission_source_id"
                            @bar-clicked="$emit('source-clicked', $event)"
                            v-if="!loading"
                        >
                        </D3HorizontalBar>
                        <Skeleton width="100%" height="200px" v-else />
                    </transition>
                </template>
            </Card>
            <Card>
                <template #title>
                    <div class="ml-2">
                        <p class="text-mini text-gray-500 m-0">
                            {{ $t("in_ton") }}
                        </p>
                        <h2>{{ $t("emissions_per_site") }}</h2>
                    </div>
                </template>

                <template #content>
                    <transition mode="out-in" name="slow-fade">
                        <D3HorizontalBar
                            :data="siteData"
                            chartKey="siteData"
                            labelKey="site_name"
                            idKey="site_id"
                            @bar-clicked="handleChartClicked"
                            v-if="!loading"
                        ></D3HorizontalBar>
                        <Skeleton width="100%" height="200px" v-else />
                    </transition>
                </template>
            </Card>
        </div>
        <div class="flex flex-column gap-4">
            <Card class="cc-stat-card cc-stat-card--blue">
                <template #content>
                    <div
                        class="flex justify-content-between align-items-center"
                    >
                        <h5 class="card-title text-white m-0 light">
                            {{ $t("total_emissions") }}
                        </h5>
                        <i class="fas fa-smoke text-white text-xl"></i>
                    </div>
                    <div>
                        <span class="h1 font-weight-bold mb-0 text-white">
                            {{ total }}
                        </span>
                        <span
                            class="text-nowrap text-tiny ml-3 text-white light"
                        >
                            {{ $t("ton_co2e") }}
                        </span>
                    </div>
                </template>

                <template #footer>
                    <div class="flex justify-content-between">
                        <span class="text-nowrap color-success light"></span>
                        <span class="text-nowrap">
                            <b-button
                                size="sm"
                                variant="outline-light"
                                @click="
                                    $router.push({
                                        path: '/data-input',
                                        query: { catId: filters.category },
                                    })
                                "
                            >
                                {{ $t("view_data") }}
                                <i class="fas fa-chevron-right ml-2"></i>
                            </b-button>
                        </span>
                    </div>
                </template>
            </Card>

            <Card>
                <template #title>
                    <div class="ml-2">
                        <p class="text-mini text-gray-500 m-0">
                            {{ $t("in_ton") }}
                        </p>
                        <h2>{{ $t("emissions_per_bu") }}</h2>
                    </div>
                </template>

                <template #content>
                    <transition mode="out-in" name="slow-fade">
                        <template v-if="!loading">
                            <D3Pie
                                :data="buData"
                                chartKey="buData"
                                labelKey="business_unit_name"
                                idKey="business_unit_id"
                                colourKey="business_unit_colour"
                                @arc-clicked="handleChartClicked"
                            ></D3Pie>
                        </template>
                        <Skeleton width="100%" height="200px" v-else />
                    </transition>
                </template>
            </Card>
        </div>
    </div>
</template>

<script>
import D3Bar from "@/components/charts/D3Bar";
import D3HorizontalBar from "@/components/charts/D3HorizontalBar";
import D3Pie from "@/components/charts/D3Pie";
import ValueChainGraph from "@/components/charts/ValueChainGraph";
import InsightsMixin from "../mixins/InsightsMixin.vue";
import Card from "primevue/card";
import Skeleton from "primevue/skeleton";

export default {
    mixins: [InsightsMixin],
    components: {
        D3Bar,
        D3HorizontalBar,
        D3Pie,
        ValueChainGraph,
        Card,
        Skeleton,
    },
    data() {
        return {
            catEvolution: [],
            sourceData: [],
            siteData: [],
            loading: true,
        };
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            this.getTotal();
            this.loading = true;
            const catEvolution = this.getCatEvolution();
            const buData = this.getBuData();
            const catData = this.getCatData();
            const sourceData = this.getSourceData();
            const siteData = this.getSiteData();
            await Promise.all([
                catEvolution,
                catData,
                sourceData,
                siteData,
                buData,
            ]);
            this.loading = false;
        },
    },
};
</script>
