<template>
    <transition name="fade" mode="out-in">
        <div
            class="flex flex-column justify-content-center align-items-center w-full h-full my-4"
            v-if="showLoading"
        >
            <ProgressSpinner />
            <p class="text-500 text-sm text-center mt-2">{{ message }}</p>
        </div>
        <div v-else>
            <slot />
        </div>
    </transition>
</template>

<script setup lang="ts">
import { computed } from "vue";
import ProgressSpinner from "primevue/progressspinner";
import { useStore } from "vuex";

const props = defineProps({
    loading: {
        type: Boolean,
        required: true,
    },
    message: {
        type: String,
        required: false,
        default: "",
    },
});

const store = useStore();

const showLoading = computed(() => {
    return props.loading || store.state.data.preloaded === false;
});
</script>
