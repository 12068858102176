<template>
    <div>
        <div class="flex justify-content-between align-items-center gap-4">
            <Card class="cc-stat-card cc-stat-card--darkblue">
                <template #content>
                    <div
                        class="flex justify-content-between align-items-center"
                    >
                        <h5 class="card-title text-white m-0 light">
                            {{ $t("dashboard_sc_total_emissions") }}
                        </h5>
                        <i class="fas fa-smoke text-white text-xl"></i>
                    </div>
                    <span class="h2 font-weight-bold mb-0 text-white">
                        {{ totalEmissions }}
                    </span>
                    <p class="text-nowrap text-white light m-0 mt-4 text-sm">
                        tCO₂e
                    </p>
                </template>
            </Card>
            <Card class="cc-stat-card cc-stat-card--blue">
                <template #content>
                    <div
                        class="flex justify-content-between align-items-center"
                    >
                        <h5 class="card-title text-white m-0 light">
                            {{ $t("dashboard_sc_emissions_fte") }}
                        </h5>
                        <i class="fas fa-user text-white text-xl"></i>
                    </div>
                    <span class="h2 font-weight-bold mb-0 text-white">
                        {{ totalEmissionsPerFTE }}
                    </span>
                    <p class="text-nowrap text-white light m-0 mt-4 text-sm">
                        tCO₂e
                    </p>
                </template>
            </Card>
            <Card class="cc-stat-card cc-stat-card--red">
                <template #content>
                    <div
                        class="flex justify-content-between align-items-center"
                    >
                        <h5 class="card-title text-white m-0 light">
                            {{ $t("dashboard_sc_emissions_per_milion") }}
                        </h5>
                        <i class="fas fa-euro text-white text-xl"></i>
                    </div>
                    <span class="h2 font-weight-bold mb-0 text-white">
                        {{ totalEmissionsPerMillionEuro }}
                    </span>
                    <p class="text-nowrap text-white light m-0 mt-4 text-sm">
                        tCO₂e
                    </p>
                </template>
            </Card>
        </div>
    </div>
</template>

<script>
import Card from "primevue/card";

export default {
    components: {
        Card,
    },
    props: {
        totalEmissions: {
            type: [String, Number],
            required: true,
        },
        totalEmissionsPerFTE: {
            type: [String, Number],
            required: true,
        },
        totalEmissionsPerMillionEuro: {
            type: [String, Number],
            required: true,
        },
    },
};
</script>
