<template>
    <div>
        <div class="flex w-full justify-content-between gap-4">
            <div class="flex flex-column flex-grow-1 gap-4">
                <Card>
                    <template #title>
                        <div class="ml-2">
                            <p class="text-mini text-gray-500 m-0">
                                {{ $t("in_ton") }}
                            </p>
                            <h2>{{ $t("across_value_chain") }}</h2>
                        </div>
                    </template>
                    <template #content>
                        <transition mode="out-in" name="slow-fade">
                            <ValueChainGraph
                                :data="catData"
                                chartKey="valueChain"
                                idKey="cat_id"
                                labelKey="cat_name"
                                @bar-clicked="$emit('category-clicked', $event)"
                                v-if="!loading"
                            ></ValueChainGraph>
                            <Skeleton width="100%" height="200px" v-else />
                        </transition>
                    </template>
                </Card>
                <Card>
                    <template #title>
                        <div class="ml-2">
                            <p class="text-mini text-gray-500 m-0">
                                {{ $t("in_ton") }}
                            </p>
                            <h2>{{ $t("emissions_per_category") }}</h2>
                        </div>
                    </template>

                    <template #content>
                        <transition mode="out-in" name="slow-fade">
                            <D3HorizontalBar
                                :data="catData"
                                chartKey="catData"
                                labelKey="cat_name"
                                idKey="cat_id"
                                @bar-clicked="$emit('category-clicked', $event)"
                                barStyle="bar__green"
                                v-if="!loading"
                            ></D3HorizontalBar>
                            <Skeleton width="100%" height="200px" v-else />
                        </transition>
                    </template>
                </Card>

                <Card>
                    <template #title>
                        <div class="ml-2">
                            <p class="text-mini text-gray-500 m-0">
                                {{ $t("in_ton") }}
                            </p>
                            <h2>{{ $t("emissions_per_site") }}</h2>
                        </div>
                    </template>

                    <template #content>
                        <transition mode="out-in" name="slow-fade">
                            <D3HorizontalBar
                                :data="siteData"
                                chartKey="siteData"
                                labelKey="site_name"
                                idKey="site_id"
                                @bar-clicked="handleChartClicked"
                                v-if="!loading"
                            ></D3HorizontalBar>
                            <Skeleton width="100%" height="200px" v-else />
                        </transition>
                    </template>
                </Card>
            </div>
            <div class="flex flex-column gap-4" style="max-width: 33%">
                <Card
                    type=""
                    icon="fas fa-smoke stats-card__icon"
                    iconClasses="text-white"
                    class="cc-stat-card cc-stat-card--blue"
                >
                    <template #content>
                        <div
                            class="flex justify-content-between align-items-center"
                        >
                            <h5 class="card-title text-white m-0 light">
                                {{ $t("total_emissions") }}
                            </h5>
                            <i class="fas fa-smoke text-white text-xl"></i>
                        </div>
                        <span class="h2 font-weight-bold mb-0 text-white">
                            {{ total }}
                        </span>
                        <span
                            class="text-nowrap text-tiny ml-3 text-white light"
                        >
                            {{ $t("ton_co2e") }}
                        </span>
                    </template>

                    <template #footer>
                        <div class="flex justify-content-between">
                            <span
                                class="text-nowrap color-success light"
                            ></span>
                            <span class="text-nowrap">
                                <a
                                    href="#"
                                    class="text-white"
                                    @click.prevent="$router.push('/data-input')"
                                >
                                    {{ $t("view_data") }}
                                    <i class="fas fa-chevron-right ml-2"></i>
                                </a>
                            </span>
                        </div>
                    </template>
                </Card>
                <Card>
                    <template #title>
                        <div class="ml-2">
                            <p class="text-mini text-gray-500 m-0">
                                {{ $t("in_ton") }}
                            </p>
                            <h2>
                                {{ $t("emissions_per_bu") }}
                            </h2>
                        </div>
                    </template>

                    <template #content>
                        <transition mode="out-in" name="slow-fade">
                            <D3Pie
                                :data="buData"
                                chartKey="buData"
                                labelKey="business_unit_name"
                                idKey="business_unit_id"
                                colourKey="business_unit_colour"
                                @arc-clicked="handleChartClicked"
                                v-if="!loading"
                            ></D3Pie>
                            <Skeleton width="100%" height="200px" v-else />
                        </transition>
                    </template>
                </Card>
            </div>
        </div>
    </div>
</template>

<script>
import D3HorizontalBar from "@/components/charts/D3HorizontalBar.vue";
import D3Pie from "@/components/charts/D3Pie.vue";
import ValueChainGraph from "@/components/charts/ValueChainGraph.vue";
import InsightsMixin from "../mixins/InsightsMixin.vue";
import Card from "primevue/card";
import Skeleton from "primevue/skeleton";

export default {
    mixins: [InsightsMixin],
    components: {
        Card,
        D3HorizontalBar,
        D3Pie,
        ValueChainGraph,
        Skeleton,
    },
    data() {
        return {
            catData: [],
            siteData: [],
            buData: [],
            loading: true,
        };
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            this.getTotal();
            this.loading = true;
            const catData = this.getCatData();
            const siteData = this.getSiteData();
            const buData = this.getBuData();
            await Promise.all([catData, siteData, buData]);
            this.loading = false;
        },
    },
};
</script>
