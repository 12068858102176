<template>
    <div>
        <span
            :id="`${metricValue.id}-metric-description`"
            v-tooltip.bottom="metricValue.description"
        >
            {{ description }}
            {{ metricValue.description?.length > 40 ? "..." : "" }}
        </span>
    </div>
</template>

<script>
export default {
    props: {
        metricValue: {
            type: Object,
            required: true,
        },
    },
    computed: {
        description() {
            // return the first 40 characters of the description
            return this.metricValue.description?.slice(0, 40);
        },
    },
};
</script>
