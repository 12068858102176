<template>
    <div id="app" v-if="isIE === false">
        <cc-side-bar v-if="auth.isAuthenticated && user" />

        <div class="main-content">
            <router-view v-slot="{ Component }">
                <transition name="fade" mode="out-in">
                    <component :is="Component" />
                </transition>
            </router-view>
        </div>

        <Loader />
        <ConfirmDialog />

        <!-- Error catching modal -->
        <error></error>

        <!-- Toast -->
        <Toast />
    </div>
    <div id="app" v-else>
        <p id="outdated-browser">
            {{ $t("app_outdated_browser_text") }}
        </p>
    </div>
</template>

<script>
import intercom from "@/util/intercom";

import Loader from "@/components/elements/Loader.vue";
import Toast from "@/components/elements/Toast.vue";
import CcSideBar from "@/components/navigation/sidebar/CcSideBar.vue";
import Error from "@/components/pages/Error.vue";
import AuthMixin from "@/mixins/auth/AuthMixin.js";
import { useAuth0 } from "@auth0/auth0-vue";
import ConfirmDialog from "primevue/confirmdialog";

import companyApi from "@/api/company";
import userApi from "@/api/user";
import * as types from "@/store/types";
import { mapState } from "vuex";

export default {
    components: {
        CcSideBar,
        Error,
        Toast,
        Loader,
        ConfirmDialog,
    },
    mixins: [
        AuthMixin, // login, logout
    ],
    data() {
        return {
            isIE: false,
            loaded: false,
            unAuthenticatedRoutes: [
                "Login",
                "Invite",
                "StartTrial",
                "Welcome",
                "NoAccess",
                "Register",
            ],
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            company: (state) => state.company,
            preloaded: (state) => state.data.preloaded,
        }),
        auth() {
            return useAuth0();
        },
    },
    created() {
        this.IECheck();
    },
    methods: {
        async init() {
            await this.initInternalData();
            this.hideLoaderOnUnauthenticated();
        },
        hideLoaderOnUnauthenticated() {
            if (
                this.unAuthenticatedRoutes.includes(this.$route.name) ||
                !this.$route.name
            ) {
                this.$store.dispatch(types.SHOW_LOADER, false);
            }
        },
        async initInternalData() {
            // Can't do this for unauthorized users, but they need to be able to see the unauthenticated pages
            if (this.$auth0.isAuthenticated.value === false) {
                if (this.unAuthenticatedRoutes.includes(this.$route.name))
                    return;
            }

            try {
                // Get an updated auth token
                const authToken = await this.auth.getAccessTokenSilently();
                sessionStorage.setItem("auth_token", authToken);

                // Get all available companies
                const companies = await userApi.getAvailableCompanies();

                // If no companies are available, throw an error
                if (companies.data.length === 0) throw "";
                // Otherwise save these companies to the store
                await this.$store.dispatch(types.SET_COMPANIES, companies.data);

                // Load in the current company data
                const company = await companyApi.getDetails();
                await this.$store.dispatch(types.SET_COMPANY, company.data);

                // Load in the user data last, because there are watchers waiting for this dispatch
                const user = await userApi.getUser();
                await this.$store.dispatch(types.SET_USER, user.data);

                // Populate global data
                await this.$store.dispatch(types.POPULATE);

                // After all of this, initialize external services
                this.initExternalServices();
            } catch (error) {
                await this.$store.dispatch(types.THROW_ERROR, {
                    title: "User cannot be found",
                    error: `We are not able to find a company associated with this user. Please check if your trial period has expired.<br><br>
                        For more support, please contact us via email.<br>
                        <a href="mailto:info@futureproofed.com">info@futureproofed.com</a>`,
                    redirectOnOk: "login",
                    logout: true,
                });
            } finally {
                this.$store.dispatch(types.SHOW_LOADER, false);
            }
        },
        initExternalServices() {
            intercom.load();
            intercom.boot({
                ...this.user,
                user_id: `FPB-${this.user.id}`,
                company_id: `FPB-${this.company.id}`,
            });
        },
        IECheck() {
            this.isIE =
                !!window.MSInputMethodContext && !!document.documentMode;
        },
    },
    watch: {
        $route: {
            handler() {
                if (this.preloaded)
                    this.$store.dispatch(types.SHOW_LOADER, false);
                this.init();
            },
        },
    },
};
</script>
