<template>
    <div>
        <div d3-chart :id="chartKey" v-show="!hideGraph"></div>
        <div v-if="hideGraph" class="ml-2 text-gray-500 text-tiny">
            {{ $t("charts_no_data") }}
        </div>
    </div>
</template>

<script>
import * as d3 from "d3";

export default {
    props: {
        data: {
            type: Array,
            required: true,
        },
        labelKey: {
            type: String,
            required: true,
        },
        chartKey: {
            type: String,
            required: false,
            default: "d3-chart",
        },
        idKey: {
            type: String,
            required: true,
        },
        highlighted: {
            type: [String, Number],
            required: false,
            default: null,
        },
    },
    data() {
        return {
            svg: null,
            xScale: null,
            yScale: null,
            graph: null,
            valueChainData: {
                upstream: [],
                direct: [],
                downstream: [],
            },
            width: 1000,
        };
    },
    computed: {
        chartHeight() {
            return this.data.length * 80;
        },
        hideGraph() {
            let emissions = 0;
            this.data.forEach((item) => {
                emissions += item.emissions;
            });

            return emissions === 0;
        },
    },
    mounted() {
        this.generateChart();
    },
    methods: {
        calculateTotal(input) {
            return input.map((x) => x.emissions).reduce((x, y) => +x + +y, 0);
        },
        generateChart() {
            // Generate the svg
            this.svg = d3
                .select("#" + this.chartKey)
                .append("svg")
                .attr("viewBox", `0 0 ${this.width} 100`);

            // Generate helper functions for scale
            this.xScale = d3
                .scaleLinear()
                .rangeRound([0, 950])
                .domain([0, this.calculateTotal(this.data)]);
            this.yScale = d3
                .scaleBand()
                .range([0, 100])
                .padding(0.85)
                .domain(this.sortedData().map((x) => x[this.idKey]));

            // This gets updated after every generated bar, gives us the x-offset
            let graphOffset = 0;

            // Create the bars
            this.graph = this.svg
                .selectAll(".bar")
                .data(this.sortedData())
                .enter()
                .append("rect")
                .attr("class", (data) => {
                    let classes = `bar bar__stacked`;
                    if (this.highlighted === null) return classes;

                    if (data.cat_id === +this.highlighted) return classes;
                    else return `${classes} bar__faded`;
                })
                .attr("height", 40)
                .attr("width", (data) => this.xScale(data.emissions))
                .attr("y", 30)
                .attr("x", (data) => {
                    // Calculate offset every time based on previously calculated value
                    const offset = graphOffset;
                    graphOffset += this.xScale(Math.round(data.emissions));
                    return offset;
                })
                .attr("fill", (data) => {
                    // Fill with the appropriate scope colour
                    switch (data.scope) {
                        case "1":
                            return "#5bc8ae";
                        case "2":
                            return "#a6b4c6";
                        case "3":
                            return "#d1d0ea";
                    }
                })
                .attr("stroke", "white") // Puts some space between each block
                .attr("id", (data) => `bar-${data[this.idKey]}`)
                .attr("rx", 1.5)
                .on("mouseover", (e, data) => this.highlightBar(data))
                .on("mouseout", (e, data) => {
                    this.addHoverData(e, null);
                    this.removeHighlight(data);
                })
                .on("mousemove", (e, data) => this.addHoverData(e, data))
                .on("mouseup", (e, data) => this.$emit("bar-clicked", data));

            // Calculate the tick values, only seperating value chains
            const tickValues = [
                0,
                this.calculateTotal(this.valueChainData.direct),
                this.calculateTotal([
                    ...this.valueChainData.upstream,
                    ...this.valueChainData.direct,
                ]),
                this.calculateTotal(this.data),
            ];

            // Add the ticks
            this.svg
                .append("g")
                .attr("transform", `translate(20, ${100 - 20})`)
                .call(
                    d3
                        .axisBottom(this.xScale)
                        .tickSize(-55)
                        .tickValues(tickValues)
                        .tickFormat(d3.format(".2s"))
                );

            // Tick styling
            this.svg.selectAll("g .domain").attr("stroke", "transparent");

            this.svg.selectAll("g .tick").attr("stroke-dasharray", "3,3");

            this.svg.selectAll("g .tick line").attr("stroke", "#adb5bd");

            this.addLabels(tickValues);
        },
        addHoverData(e, data) {
            const coords = d3.pointer(e);
            this.svg.selectAll(".hover-data, .hover-data__background").remove();

            if (data !== null) {
                const hoverbox = this.svg
                    .append("rect")
                    .attr("fill", "white")
                    .attr("height", 30)
                    .attr("stroke", "black")
                    .attr("rx", 5)
                    .classed("hover-data__background", true);
                const hovertext = this.svg
                    .append("text")
                    .attr(
                        "transform",
                        `translate(${coords[0] + 10}, ${coords[1] - 15})`
                    )
                    .attr(
                        "text-anchor",
                        coords[0] < this.width / 2 ? "start" : "end"
                    )
                    .attr("alignment-baseline", "alphabetic")
                    .classed("hover-data", true)
                    .text(
                        `${this.$t(data.cat_name)} - ${this.$checkDecimals(
                            data.emissions
                        )} ${data.unit}`
                    );
                const hovertextWidth = hovertext._groups[0][0].getBBox().width;
                hoverbox.attr("width", hovertextWidth + 20);
                hoverbox.attr(
                    "transform",
                    "translate(" +
                        (coords[0] < this.width / 2
                            ? coords[0]
                            : coords[0] - hovertextWidth) +
                        "," +
                        (coords[1] - 35) +
                        ")"
                );
            }
        },
        highlightBar(data) {
            const element = document.getElementById(`bar-${data[this.idKey]}`);
            element.setAttribute("fill-opacity", ".5");
        },
        removeHighlight(data) {
            const element = document.getElementById(`bar-${data[this.idKey]}`);
            element.setAttribute("fill-opacity", 1);
        },
        addLabels(tickValues) {
            // The upstream, direct and downstream labels were quite a hassle to add, so this might seem a bit convoluted.
            // Basically, the idea is to start off from the calculated tick values, add the next block and divide it by half.
            // After that, we need to shift the text by half the width of the text, as to try and center it above the block
            const upstreamText = this.svg
                .append("text")
                .text(this.$t("chart_direct"))
                .attr("fill", "#adb5bd")
                .classed("text-tiny", true);
            const upstreamTextWidth =
                upstreamText._groups[0][0]?.getBBox().width;
            if (upstreamTextWidth) {
                upstreamText.attr(
                    "transform",
                    `translate(${
                        this.xScale(tickValues[1]) / 2 - upstreamTextWidth / 2
                    }, 10)`
                );
            }

            const directText = this.svg
                .append("text")
                .text(this.$t("chart_upstream"))
                .attr("fill", "#adb5bd")
                .classed("text-tiny", true);
            const directTextWidth = directText._groups[0][0]?.getBBox().width;
            if (directTextWidth) {
                const directTextX =
                    this.xScale(tickValues[1]) +
                    this.xScale(tickValues[2] - tickValues[1]) / 2 -
                    directTextWidth / 2;
                directText.attr("transform", `translate(${directTextX}, 10)`);
            }

            const downstreamText = this.svg
                .append("text")
                .text(this.$t("chart_downstream"))
                .attr("fill", "#adb5bd")
                .classed("text-tiny", true);
            const downstreamTextWidth =
                downstreamText._groups[0][0]?.getBBox().width;
            if (downstreamTextWidth) {
                const downstreamTextX =
                    this.xScale(tickValues[2]) + // Start at the previous tick
                    this.xScale(tickValues[3] - tickValues[2]) / 2 - // Add up half of the third part
                    downstreamTextWidth / 2; // Substract half of the text width
                downstreamText.attr(
                    "transform",
                    `translate(${downstreamTextX}, 10)`
                );
            }
        },
        sortedData() {
            const sortedArray = [...this.data].sort((x, y) => {
                return x.cat_id - y.cat_id;
            });
            this.valueChainData.upstream = sortedArray.filter(
                (x) => x.value_chain === "upstream"
            );
            this.valueChainData.direct = sortedArray.filter(
                (x) => x.value_chain === "direct"
            );
            this.valueChainData.downstream = sortedArray.filter(
                (x) => x.value_chain === "downstream"
            );

            return [
                ...this.valueChainData.direct,
                ...this.valueChainData.upstream,
                ...this.valueChainData.downstream,
            ];
        },
    },
};
</script>
