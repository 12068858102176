<template>
    <Card>
        <template #title>
            <div>
                <p class="text-mini text-gray-500 m-0">{{ $t("in_ton") }}</p>
                <h2 class="mt-2">{{ $t("dashboard_value_chain_header") }}</h2>
            </div>
        </template>

        <template #content>
            <transition mode="out-in" name="slow-fade">
                <template v-if="!loading">
                    <D3StackedBar
                        :data="valuechainData"
                        chartKey="valuechainData"
                        labelKey="value_chain"
                        idKey="value_chain"
                        :stackKeys="['scope1', 'scope2', 'scope3']"
                        :width="450"
                        :padding="0.5"
                        @bar-clicked="$emit('chart-clicked', $event)"
                    ></D3StackedBar>
                </template>

                <Skeleton width="100%" height="200px" v-else />
            </transition>
        </template>
    </Card>
</template>

<script>
import D3StackedBar from "@/components/charts/D3StackedBar.vue";
import Card from "primevue/card";
import Skeleton from "primevue/skeleton";

export default {
    components: {
        D3StackedBar,
        Card,
        Skeleton,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            valuechainData: [],
        };
    },
    mounted() {
        this.generateValueChainData();
    },
    methods: {
        generateValueChainData() {
            this.loading = true;

            const order = ["upstream", "direct", "downstream"];

            this.valuechainData = this.data.totalsByValueChain.map((x) => {
                let emissions = {};
                x.totalsByScope.forEach((y) => {
                    emissions[`scope${y.scope}`] = y.total;
                });
                return {
                    ...x,
                    emissions,
                    unit: "tCO₂e",
                };
            });

            // Sort according to logical order in above array
            this.valuechainData.sort((a, b) => {
                const firstIndex = order.findIndex((x) => x === a.value_chain);
                const secondIndex = order.findIndex((x) => x === b.value_chain);
                if (firstIndex > secondIndex) return 1;
                if (firstIndex < secondIndex) return -1;
                return 0;
            });

            this.loading = false;
        },
    },
    watch: {
        data: {
            handler() {
                this.generateValueChainData();
            },
        },
    },
};
</script>
