import { createRouter, createWebHistory } from "vue-router";

import { authGuard } from "@auth0/auth0-vue";
// import { authGuard } from "./guards/authGuard";

import Dashboard from "@/views/Dashboard.vue";
import Login from "@/views/Login.vue";

import CustomMetrics from "@/views/CustomMetrics.vue";
import BusinessUnits from "@/views/insights/BusinessUnits.vue";
import Category from "@/views/insights/Category.vue";
import Sites from "@/views/insights/Sites.vue";

import CompanyGeneral from "@/components/company/CompanyGeneral.vue";
import CompanyPeriod from "@/components/company/CompanyPeriodSettings.vue";
import CompanyStructure from "@/components/company/CompanyStructure.vue";
import EmissionCategorySelection from "@/components/company/EmissionCategorySelection.vue";
import Tags from "@/components/company/Tags";
import Team from "@/components/company/Team";
import DataConfiguration from "@/components/company/CompanyDataConfiguration.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        redirect: "/dashboard",
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        beforeEnter: authGuard,
    },
    {
        path: "/company-metrics",
        name: "Company metrics",
        component: CustomMetrics,
        beforeEnter: authGuard,
    },
    {
        path: "/profile",
        name: "Profile",
        component: () =>
            import(
                /* webpackChunkName: "Profile" */ "@/components/profile/Profile.vue"
            ),
        beforeEnter: authGuard,
    },
    {
        path: "/data-input",
        name: "DataInput",
        component: () =>
            import(/* webpackChunkName: "DataInput" */ "@/views/DataInput.vue"),
        beforeEnter: authGuard,
    },
    {
        path: "/company",

        component: () => import("@/views/Company.vue"),
        beforeEnter: authGuard,
        children: [
            {
                name: "Company info",
                path: "info",
                component: CompanyGeneral,
            },
            {
                name: "Period settings",
                path: "period-settings",
                component: CompanyPeriod,
            },
            {
                name: "Company structure",
                path: "structure",
                component: CompanyStructure,
            },
            {
                name: "Emission categories",
                path: "emission-categories",
                component: EmissionCategorySelection,
            },
            {
                name: "Data configuration",
                path: "data-configuration",
                component: DataConfiguration,
            },
            {
                name: "Tags",
                path: "tags",
                component: Tags,
            },
            {
                name: "Team",
                path: "team",
                component: Team,
            },
            {
                path: "",
                redirect: { name: "Company info" },
            },
        ],
    },
    {
        path: "/insightsv1",
        name: "InsightsV1",
        component: () => import("@/views/insights/Insights.vue"),
        beforeEnter: authGuard,
        children: [
            {
                path: "",
                redirect: { name: "GHG-P categories" },
            },
            {
                name: "GHG-P categories",
                path: "category",
                component: Category,
            },
            {
                name: "Sites",
                path: "sites",
                component: Sites,
            },
            {
                name: "Business units",
                path: "businessunits",
                component: BusinessUnits,
            },
        ],
    },
    {
        path: "/insights",
        name: "Insights",
        component: () => import("@/views/InsightsV2.vue"),
        beforeEnter: authGuard,
        children: [
            {
                path: ":dashboardId?",
                name: "Insights-Dashboard",
                component: () =>
                    import("@/components/insights_v2/DashboardsWrapper.vue"),
            },
            {
                path: "get-started",
                name: "Insights-No-Dashboards",
                component: () =>
                    import(
                        "@/components/insights_v2/dashboards/NoDashboards.vue"
                    ),
            },
            {
                path: ":dashboardId/drilldown/:chartId",
                name: "drilldown",
                component: () =>
                    import("@/components/insights_v2/DrilldownWrapper.vue"),
            },
        ],
    },
    {
        path: "/invite",
        name: "Invite",
        component: () =>
            import(/*webpackChunkName: "Invite" */ "@/views/Invite.vue"),
    },
    {
        path: "/starttrial",
        name: "StartTrial",
        component: () =>
            import(
                /*webpackChunkName: "StartTrial" */ "@/views/StartTrial.vue"
            ),
    },
    {
        path: "/register",
        name: "Register",
        component: () =>
            import(/*webpackChunkName: "Register" */ "@/views/Register.vue"),
    },
    {
        path: "/welcome",
        name: "Welcome",
        component: () =>
            import(/*webpackChunkName: "Welcome" */ "@/views/Welcome.vue"),
    },
    {
        path: "/target",
        name: "Target",
        component: () =>
            import(/*webpackChunkName: "Target" */ "@/views/Target.vue"),
        beforeEnter: authGuard,
    },
    {
        path: "/no-access",
        name: "NoAccess",
        component: () => import("@/components/pages/MissingPermission.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { left: 0, top: 0, behavior: "smooth" };
    },
});

export default router;
