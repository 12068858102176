<template>
    <div class="flex gap-2">
        <Button
            outlined
            icon="fa fa-arrow-left"
            :label="$t('insights_new')"
            @click="$router.push('/insights')"
        />
        <Select
            size="large"
            :options="items"
            optionLabel="name"
            optionValue="id"
            :modelValue="category"
            @update:modelValue="selectItem"
            :placeholder="$t('all_categories')"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";
import Select from "primevue/select";
import Button from "primevue/button";

export default {
    components: {
        Select,
        Button,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapState({
            category: (state) => state.filters.category,
        }),
    },
    methods: {
        selectItem(item) {
            console.log(item);
            this.$emit("item-selected", item);
        },
    },
};
</script>
