<template>
    <div>
        <ControlBar :hide="['sort']" :hideFilters="['search']">
            <template v-slot:title>
                <insight-header
                    :items="categories"
                    :title="$t(headerTitle)"
                    @item-selected="setFilter({ category: $event })"
                ></insight-header>
            </template>
        </ControlBar>

        <transition name="slide-down" mode="out-in" class="mt-4">
            <template v-if="category === null">
                <category-charts
                    class="mt-4"
                    @category-clicked="selectCategory"
                ></category-charts>
            </template>
            <template v-else>
                <category-details
                    class="mt-4"
                    @category-clicked="selectCategory"
                ></category-details>
            </template>
        </transition>
    </div>
</template>

<script>
import InsightHeader from "@/components/insights/InsightHeader";

import CategoryCharts from "@/components/insights/charts/CategoryCharts";
import CategoryDetails from "@/components/insights/charts/CategoryDetails";

import InsightsWrapperMixin from "@/components/insights/mixins/InsightsWrapperMixin";
import ControlBar from "../../components/controls/ControlBar.vue";

import { mapState } from "vuex";

export default {
    name: "InsightsCategory",
    mixins: [InsightsWrapperMixin],
    components: {
        InsightHeader,
        CategoryCharts,
        CategoryDetails,
        ControlBar,
    },
    data() {
        return {
            emptyCat: [{ name: this.$t("all_categories"), id: null }],
        };
    },
    computed: {
        ...mapState({
            availableCategories: (state) => state.data.categories,
            category: (state) => state.filters.category,
        }),
        headerTitle() {
            if (!this.category) return this.$t("all_categories");
            return this.categories.find((x) => x.id === this.category).name;
        },
        categories() {
            return [...this.emptyCat, ...this.availableCategories];
        },
    },
    methods: {
        selectCategory(cat) {
            if (cat.cat_id === -1) return this.setFilter({ category: null });
            this.setFilter({ category: cat.cat_id });
        },
    },
};
</script>
