<template>
    <LoadingWrapper :loading="loading">
        <table class="cc-table">
            <thead class="cc-table__head">
                <tr>
                    <th>{{ $t("period") }}</th>
                    <th>{{ $t("start_date") }}</th>
                    <th>{{ $t("end_date") }}</th>
                    <th>{{ $t("revenue") }}</th>
                    <th class="text-center">{{ $t("label_actions") }}</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="line in lines"
                    :key="line.id"
                    :class="line.locked === 1 ? 'cc-table__row--disabled' : ''"
                >
                    <td>{{ line.name }}</td>
                    <td>{{ formatDate(line.start_date) }}</td>
                    <td>{{ formatDate(line.end_date) }}</td>
                    <td>
                        <!-- Hardcoding euro's here now but needs changing when actually using different currency -->
                        €
                        {{
                            Number.parseFloat(
                                line.revenue || 0
                            ).toLocaleString()
                        }}
                    </td>
                    <td class="text-center pl-6">
                        <RevenueTableActionButtons
                            @edit="edit(line)"
                            @lock="lock(line)"
                            @unlock="unlock(line)"
                            @delete="deletePeriod(line)"
                            :line="line"
                        />
                    </td>
                </tr>
                <tr>
                    <td colspan="6">
                        <span
                            class="text-gray-500 text-button"
                            @click="handleAddYear"
                            v-if="$can('create', 'period')"
                        >
                            <i class="far fa-plus-circle mr-2"></i>
                            {{ $t("periodForm_add") }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>

        <period-form
            :showModal="showAddPeriodForm"
            @update:showModal="handleClosed"
            @close="handleClosed"
            @saved="handleSaved"
            :previousPeriodId="previousPeriodId"
            :startDate="recommendedStartDate"
            :editPeriod="editing"
        ></period-form>
    </LoadingWrapper>
</template>

<script>
import PeriodForm from "./PeriodForm.vue";
import RevenueTableActionButtons from "./RevenueTableActionButtons.vue";

import periodApi from "@/api/period";
import DateMixin from "@/mixins/date/DateMixin";
import swal from "sweetalert2";

import * as types from "@/store/types";
import LoadingWrapper from "@/components/elements/LoadingWrapper.vue";

export default {
    mixins: [DateMixin],
    components: {
        PeriodForm,
        RevenueTableActionButtons,
        LoadingWrapper,
    },
    data() {
        return {
            lines: [],
            newLine: { name: "", revenue: "" },
            editing: null,
            showAddPeriodForm: false,
            loading: true,
        };
    },
    computed: {
        previousPeriodId() {
            if (this.lines.length === 0) return -1;
            return this.lines[this.lines.length - 1].id;
        },
        recommendedStartDate() {
            if (this.lines.length === 0)
                return new Date().toISOString().split("T")[0];

            const lastEndDate = new Date(this.lines.at(-1).end_date);
            const firstStartDate = lastEndDate.setDate(
                lastEndDate.getDate() + 1
            );
            return new Date(firstStartDate);
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.getPeriods();
        },
        async getPeriods() {
            this.loading = true;

            try {
                const res = await periodApi.list();
                this.lines = res.data;
                this.$store.dispatch(types.SET_PERIODS, res.data);
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        edit(line) {
            this.editing = line;
            this.showAddPeriodForm = true;
        },
        async lock(period) {
            const response = await swal.fire({
                title: this.$t("companyRevenueTable_lock_title"),
                text: `${period.name}
                ${this.$t("companyRevenueTable_lock_text")}`,
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: this.$t("cancel"),
            });

            if (response.isConfirmed) {
                try {
                    await periodApi.lock(period.id, 1);
                    this.init();
                } catch (error) {
                    console.log(error);
                }
            }
        },
        async unlock(period) {
            const response = await swal.fire({
                title: this.$t("companyRevenueTable_unlock_title"),
                text: `${period.name}
                ${this.$t("companyRevenueTable_unlock_text")}`,
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: this.$t("cancel"),
            });

            if (response.isConfirmed) {
                try {
                    await periodApi.lock(period.id, 0);
                    this.init();
                } catch (error) {
                    console.log(error);
                }
            }
        },
        async deletePeriod(period) {
            const response = await swal.fire({
                title: this.$t("companyRevenueTable_deletePeriod_title"),
                text: this.$t("companyRevenueTable_deletePeriod_text", {
                    period: period.name,
                }),
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: this.$t("cancel"),
                input: "text",
            });

            if (response.value === period.name && response.isConfirmed) {
                try {
                    await periodApi.delete(period.id);
                    this.init();
                } catch (error) {
                    console.log(error);
                }
            }
        },
        handleSaved() {
            this.init();
            this.showAddPeriodForm = false;
            this.editing = null;
        },
        handleClosed() {
            this.showAddPeriodForm = false;
            this.editing = null;
            this.getPeriods();
        },
        handleAddYear() {
            this.showAddPeriodForm = true;
        },
    },
};
</script>
