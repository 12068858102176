<template>
    <div class="pb-8">
        <!--Metric Value Header -->
        <div class="flex justify-content-end align-items-center my-4">
            <Button
                v-if="$can('create', 'metric')"
                @click="createCustomMetric"
                icon="fa fa-plus"
                :label="$t('new_metric')"
            />
        </div>

        <!-- Empty Custom Metrics View -->
        <empty-metric-view v-if="!customMetrics" />

        <!-- Custom Metrics List -->
        <div v-else>
            <!-- Custom Metric Header -->
            <metric-header
                @expand-all="toggleExpandAll"
                @collapse-all="toggleCollapseAll"
                :expandable="expandable"
            />

            <div v-for="customMetric in customMetrics" :key="customMetric.id">
                <metric-accordion
                    :ref="`metricAccordion-${customMetric.id}`"
                    :customMetric="customMetric"
                    :expandAll="expandAll"
                    :collapseAll="collapseAll"
                    @select-metric="selectMetric"
                    @delete-metric="openDeleteMetricModal"
                >
                    <metric-accordion-table
                        :ref="`metricAccordionTable-${customMetric.id}`"
                        :customMetric="customMetric"
                        :navigate-metric-value="navigateMetricValueItem"
                        @reset-navigation="navigateMetricValueItem = 0"
                        @select-metric-value="selectedCustomMetricAction"
                    />
                </metric-accordion>
            </div>
        </div>

        <!-- Custom Metrics Sidebar -->
        <metric-sidebar
            ref="metric_sidebar_component"
            :selectedCustomMetric="selectedCustomMetric"
            :selectedCustomMetricValue="selectedCustomMetricValue"
            @navigate-metric-values="navigateMetricValues"
            @refresh="handleRefresh"
        >
            <template v-slot:attachments>
                <MetricsAttachments
                    name="attachments"
                    :customMetricValueId="selectedCustomMetricValue.id"
                />
            </template>
        </metric-sidebar>
    </div>
</template>
<script>
import customMetricsApi from "@/api/custom_metrics";
import MetricAccordion from "@/components/custom_metrics/metricsAccordion/MetricAccordion.vue";
import MetricHeader from "@/components/custom_metrics/MetricHeader.vue";
import MetricSidebar from "@/components/custom_metrics/metricsSidebar/MetricSidebar.vue";
import { mapState } from "vuex";
import EmptyMetricView from "./EmptyMetricView.vue";
import MetricAccordionTable from "./metricsAccordion/MetricAccordionTable.vue";
import MetricsAttachments from "./metricsSidebar/data-input/MetricAttachments.vue";
import Button from "primevue/button";

export default {
    name: "MetricList",
    components: {
        MetricSidebar,
        MetricHeader,
        MetricAccordion,
        EmptyMetricView,
        MetricAccordionTable,
        MetricsAttachments,
        Button,
    },

    data() {
        return {
            customMetrics: [],
            selectedCustomMetric: null,
            selectedCustomMetricValue: null,
            expandAll: false,
            collapseAll: false,
            loading: false,
            expandable: true,
            navigateMetricValueItem: 0,
            sidebarVisible: false,
        };
    },

    mounted() {
        this.init();
    },

    computed: {
        ...mapState({
            businessUnits: (state) => state.data.businessUnits,
            sites: (state) => state.data.sites,
            periodId: (state) => state.filters.period,
        }),

        selectSites() {
            const sites = this.sites.map((x) => {
                return { value: x.id, text: x.name };
            });
            return sites;
        },

        selectBu() {
            const bu = this.businessUnits.map((x) => {
                return { value: x.id, text: x.name };
            });
            return bu;
        },
    },

    methods: {
        async init() {
            await this.getAllCustomMetrics();
        },

        async getAllCustomMetrics() {
            try {
                this.loading = true;
                const response = await customMetricsApi.list();
                this.customMetrics = response.data.data;
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },

        // Custom metric actions //
        createCustomMetric() {
            this.selectedCustomMetric = null;
            this.selectedCustomMetricValue = null;
            this.$refs.metric_sidebar_component.open();
        },

        selectMetric(metric) {
            this.selectedCustomMetric = { ...metric };
            this.selectedCustomMetricValue = null;
            this.$refs.metric_sidebar_component.open();
        },

        openDeleteMetricModal(metric) {
            this.$confirm.require({
                message: this.$t("delete_metric_message"),
                header: `${this.$t("delete")} ${metric.name} ?`,
                icon: "fa fa-trash",
                rejectProps: {
                    label: this.$t("cancel"),
                    class: "secondary",
                    outlined: true,
                },
                acceptProps: {
                    label: this.$t("delete_confirm"),
                    severity: "danger",
                },
                accept: () => {
                    this.deleteMetric(metric.id);
                },
            });
        },

        async deleteMetric(id) {
            try {
                await customMetricsApi.delete(id);
                this.init();
            } catch (error) {
                console.log(error);
            }
        },
        selectedCustomMetricAction(value) {
            // If the period changed, we should only set a new metric value when the sidebar is opened
            const sidebar = this.$refs.metric_sidebar_component;
            if (!sidebar) return;
            if (
                !sidebar.showModal &&
                !sidebar.sidebarVisible &&
                value.periodChanged
            )
                return;

            // We recieve both the metric and the metric value
            const metricValue = value.metricValue;
            const customMetric = value.customMetric;

            // If the metric value is null, we are creating a new metric value, so we set the period_id
            metricValue.period_id === null
                ? (metricValue.period_id = this.periodId)
                : metricValue;
            this.selectedCustomMetricValue = { ...metricValue };
            this.selectedCustomMetric = { ...customMetric };

            // Open the sidebar
            this.$nextTick(() => {
                this.$refs.metric_sidebar_component.open();
            });
        },

        handleRefresh() {
            this.init();
        },

        // Controls accordion expand/collapse
        toggleExpandAll() {
            this.expandAll = true;
            this.expandable = false;
            this.$nextTick(() => (this.expandAll = false));
        },
        toggleCollapseAll() {
            this.collapseAll = true;
            this.expandable = true;
            this.$nextTick(() => (this.collapseAll = false));
        },

        // Controls for navigation has been hidden for now
        navigateMetricValues(navigationDetails) {
            const { metric_id } = navigationDetails;
            this.$refs[`metricAccordionTable-${metric_id}`][0].navigateValue(
                navigationDetails
            );
        },
    },
    watch: {
        periodId() {
            this.init();
        },
    },
};
</script>
