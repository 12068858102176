<template>
    <div>
        <LoadingWrapper :loading="loading">
            <div
                class="flex justify-content-between align-items-center mb-2"
                v-for="unit in units"
                :key="unit.id"
            >
                <span>
                    <i class="fas fa-building text-gray-500"></i>
                    <Badge
                        class="badge badge-pill ml-3"
                        :style="getBadgeStyle(unit.colour)"
                    >
                        <span>{{ unit.name }}</span>
                    </Badge>
                </span>

                <Button
                    type="button"
                    icon="fa fa-ellipsis-v"
                    @click="toggle($event, unit)"
                    text
                    rounded
                    v-tooltip.bottom="$t('more_actions')"
                    v-if="
                        $can('update', 'business_unit') ||
                        $can('delete', 'business_unit')
                    "
                />
            </div>
        </LoadingWrapper>

        <Menu ref="menu" id="overlay_menu" :model="menuItems" :popup="true" />

        <!-- BU MODAL -->
        <company-business-units-modal
            :showModal="showFormModal"
            v-model="selectedUnit"
            @save="saveBusinessUnit($event)"
            @close="showFormModal = false"
        ></company-business-units-modal>
    </div>
</template>

<script>
import Badge from "primevue/badge";
import Button from "primevue/button";
import Menu from "primevue/menu";
import buApi from "@/api/business_unit";
import CompanyBusinessUnitsModal from "@/components/company/structure/CompanyBusinessUnitsModal";
import * as types from "@/store/types";
import LoadingWrapper from "@/components/elements/LoadingWrapper.vue";
export default {
    components: {
        CompanyBusinessUnitsModal,
        Badge,
        Button,
        Menu,
    },
    data() {
        return {
            units: [],
            selectedUnit: {},
            showFormModal: false,
            menuItems: [
                {
                    label: this.$t("companyBusinessUnits_edit"),
                    icon: "fa fa-pencil",
                    visible: this.$can("update", "business_unit"),
                    command: () => this.showBusinessUnitModal(),
                },
                {
                    label: this.$t("companyBusinessUnits_delete"),
                    icon: "fa fa-trash",
                    visible: this.$can("delete", "business_unit"),
                    command: () => this.confirmDeleteBU(),
                },
            ],
            loading: true,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.getBusinessUnits();
        },
        toggle(event, unit) {
            this.selectedUnit = unit;
            this.$refs.menu.toggle(event);
        },
        showBusinessUnitModal() {
            this.showFormModal = true;
        },
        async getBusinessUnits() {
            this.loading = true;

            try {
                const units = await buApi.list();
                this.units = units.data;
                this.$store.dispatch(types.SET_DATA, {
                    name: "businessUnits",
                    data: this.units,
                });
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        async deleteBusinessUnit() {
            try {
                await buApi.delete(this.selectedUnit.id);
                this.getBusinessUnits();
                this.selectedUnit = {};
            } catch (error) {
                console.log(error);
            }
        },
        async saveBusinessUnit() {
            if (!this.selectedUnit.id) {
                try {
                    await buApi.add(
                        this.selectedUnit.name,
                        this.selectedUnit.colour
                    );
                    this.showFormModal = false;
                    this.selectedUnit = {};
                } catch (error) {
                    console.log(error);
                }
            } else {
                try {
                    await buApi.update(
                        this.selectedUnit.id,
                        this.selectedUnit.name,
                        this.selectedUnit.colour
                    );
                    this.showFormModal = false;
                    this.selectedUnit = {};
                } catch (error) {
                    console.log(error);
                }
            }

            this.getBusinessUnits();
        },
        confirmDeleteBU() {
            this.$confirm.require({
                message: this.$t("companyBusinessUnits_delete_text"),
                header: "Delete business unit?",
                icon: "fa fa-exclamation-triangle",
                rejectProps: {
                    label: this.$t("cancel"),
                    class: "secondary",
                    outlined: true,
                },
                acceptProps: {
                    label: this.$t("delete"),
                },
                accept: () => {
                    this.deleteBusinessUnit();
                },
            });
        },
        getBadgeStyle(colour) {
            if (colour) {
                return `color: ${colour}; background-color: ${colour}10; box-shadow: none;`;
            }

            return "";
        },
    },
};
</script>
