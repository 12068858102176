<template>
    <Card>
        <template #title>
            <div>
                <p class="text-mini text-gray-500 m-0">{{ $t("in_ton") }}</p>
                <h2 class="mt-2">
                    {{ $t("dashboard_scope_header") }}
                </h2>
            </div>
        </template>
        <template #content>
            <transition mode="out-in" name="slow-fade">
                <template v-if="!loading">
                    <D3Pie
                        :data="scopeData"
                        chartKey="scopeData"
                        labelKey="label"
                        idKey="id"
                        colourKey="colour"
                        @arc-clicked="$emit('chart-clicked', $event)"
                    ></D3Pie>
                </template>

                <Skeleton width="100%" height="200px" v-else />
            </transition>
        </template>
    </Card>
</template>

<script>
import Card from "primevue/card";
import Skeleton from "primevue/skeleton";
import D3Pie from "@/components/charts/D3Pie.vue";

export default {
    components: {
        D3Pie,
        Card,
        Skeleton,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            scopeData: [],
            scopes: [
                {
                    id: "scope1",
                    label: this.$t("scope") + " 1",
                    value: "1",
                    colour: "#5bc8ae",
                },
                {
                    id: "scope2",
                    label: this.$t("scope") + " 2",
                    value: "2",
                    colour: "#a6b4c6",
                },
                {
                    id: "scope3",
                    label: this.$t("scope") + " 3",
                    value: "3",
                    colour: "#d1d0ea",
                },
            ],
        };
    },
    mounted() {
        this.generatePerScopeData();
    },
    methods: {
        generatePerScopeData() {
            this.loading = true;

            const scopeData = this.data.totalsByScope.map((t) => {
                const scope = this.scopes.filter((s) => s.value == t.scope)[0];
                return {
                    emissions: t.total,
                    unit: "tCO₂e",
                    click: "scope",
                    ...scope,
                };
            });

            this.scopeData = scopeData;
            this.loading = false;
        },
    },
    watch: {
        data: {
            handler() {
                this.generatePerScopeData();
            },
            deep: true,
        },
    },
};
</script>
